import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useWindowDimensions from "../../../Hooks/UseWindowDimensions"

export default function Videos() {
    const {width} = useWindowDimensions()
    const xs = width < 576
    const sm = width >= 576 && width <768
    const md = width >= 768 && width < 992
    const lg = width >=992 

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 1400 },
          items: 1,
          partialVisibilityGutter: 40 
        },
        desktop: {
          breakpoint: { max: 1400, min: 1024 },
          items: 1,
          partialVisibilityGutter: 80
        },
        tablet: {
          breakpoint: { max: 1024, min: 730 },
          items: 1,
          partialVisibilityGutter: 20
        },
        mobile: {
          breakpoint: { max: 730, min: 0 },
          items: 1,
          partialVisibilityGutter: 0
        }
    };

    return(
        <Row id="Videos">
            <Col xs={12} lg={6}
                style={{
                    padding: !xs? "6rem 6rem" : "6rem 20px",
                    background: "black"
                }}
            >
                <h1 class="subtitle" style={{color:"white", textAlign:"left", fontSize: xs? "32px": sm? "48px" : md? "48px": "48px"}}>Videos</h1>
                <div style={{
                    padding:lg? "4rem 0" : "1rem 0"
                }}
                
                >
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlay={false}
                        autoPlaySpeed={5000}
                        // removeArrowOnDeviceType={[ "mobile"]}
                    >
                        <div>
                            <iframe width={width/3} height={width / 4} src="https://www.youtube-nocookie.com/embed/tlNn6XZ5mVk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </Carousel>
                </div>
            </Col>
            <Col xs={12} lg={6}
                style={{
                padding: !xs? "6rem 6rem" : "6rem 20px",
                background: "linear-gradient(to right,rgba(0, 0, 0, 1), transparent, rgba(0, 0, 0, 0.5) ), url('./images/ezogie-brick-shoes-cropped.jpg') no-repeat",
                minHeight:"100vh",
                backgroundBlendMode:  "multiply",
                backgroundSize: "cover",
            }}>
            </Col>
        </Row>
    )
}