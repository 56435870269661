import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import useWindowDimensions from "../../../Hooks/UseWindowDimensions"

export default function Ezogie() {
    const {width} = useWindowDimensions()
    const xs = width < 576
    const sm = width >= 576 && width <768
    const md = width >= 768 && width < 992
    const lg = width >=992 && width < 1200
    const xl =  width >=1200
    return(
      <Row id="Ezogie"
        style={{
          padding: !xs? "4rem 6rem" : "4rem 20px",
          background:"linear-gradient(to bottom, black, #060409, #251337, #2e1256, #63319b,  #2e1256,  #251337 )", 
          // background:"linear-gradient(to bottom, black, #060409, #251337, #2e1256, #a80d65,  #2e1256,  #251337 )", 
          minHeight:"100vh", 
        }}>
        <Col xs={12} xl={6}
          style={{
            position:"relative"
          }}
        >
          <img src="./images/Homepage_Ezogie_Transparent_All_Three_Edited.png" alt="Ezogie"  style={
            xl?{ maxWidth:width / 2 - 50 + "px", height:"80%", left: "0", bottom: "0"}: 
            lg? {width:"90%", bottom:"0"}: 
            md? {width:"90%", bottom:"0"}: 
            sm? {width:"80%", left:"0", bottom:"0"} : 
                {width:"100%", left:"0", bottom:"0"}
          }/>
          {/* <img src="./images/ezogie-box-filter-cropped.png" alt="Ezogie"  
          style={
            xl?{ maxWidth:width / 2 - 50 + "px", height:"80%", left: "0", bottom: "0", paddingTop:"15%"}: 
            lg? {width:"90%", bottom:"0"}: 
            md? {width:"90%", bottom:"0"}: 
            sm? {width:"80%", left:"0", bottom:"0"} : 
                {width:"100%", left:"0", bottom:"0"}
          }
          /> */}

        </Col>
        <Col xs={12} xl={6} style={{color:"white", display:"flex", flexDirection:"column", justifyContent:"center"}}>
          <Row style={{paddingBottom:"40px"}}>
            <h1 class="title" 
            style={{
              color:"white",
              fontSize: xs? "48px": sm? "62px" : md? "68px": "100px"
              }}
            > 
              Ezogie
            </h1>
          </Row>
          <Row style={{paddingBottom:"40px"}}>
            <h2 class="subtitle" style={{
              color:"white",
              fontSize: xs? "28px": sm? "30px" : md? "32px": "48px"
              }}
            > 
              Listen Now:
            </h2>
          </Row>
          <Row>
            <Col style={{justifyContent:"right", display: "flex"}}>
              <a target="_blank" rel="noreferrer"  href="https://open.spotify.com/artist/2ZAhNfaF4HXYVsNVBFQzK4">
                <img style={{width:"100%", maxWidth:"180px"}} src="./images/icons/toppng.com-listen-on-spotify-logo-1200x494.png" alt="listen on spotify" />
              </a>
            </Col>
            <Col  style={{justifyContent:"left", display: "flex"}}>
              <a target="_blank" rel="noreferrer"  href="https://music.apple.com/us/artist/ezogie/1559658906">
              <img style={{width:"100%", height:"100%",  maxWidth:"180px",  maxHeight:"180px"}} src="./images/icons/US-UK_Apple_Music_Listen_on_Badge_RGB_072720.png" alt="listen on apple music" />
              </a> 
            </Col>
          </Row>
        </Col>
      </Row>
    )
}