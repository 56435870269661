import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import CloseButton from "react-bootstrap/CloseButton";

import "./Modal.css"

export default function NewMusicModal() {
    const [show, setShow] = useState(true);
  
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
  
    return (
      <>
  
        <Modal bsPrefix="modal" show={show} onHide={handleClose}>
          
            <Modal.Body bsPrefix="modal-body">
                <Row  style={{justifyContent:"end"}}>
                    <CloseButton variant="white" onClick={handleClose}/>
                </Row>
                <Row>
                    <Col>
                        <img className="cover-photo" src="./images/songs/ride_400x400.jpg" alt="Ride-by-Ezogie"/>
                    </Col>
                    <Col style={{padding:"2rem"}}>
                        <Row>
                            <h2>
                                Ride
                            </h2>
                        </Row>
                        <Row style={{marginBottom:"2rem"}}>
                            <h5>
                                Ezogie
                            </h5>
                        </Row>
                        <Row>
                            <Col>
                                <a target="_blank" rel="noreferrer" href="https://open.spotify.com/track/4EdxOaDksCD16JIK9A5b6H?si=UoCWuX3kQzO2CmLu3eVdnw">
                                    <img alt="Spotify" className="icon" src="./images/icons/spotify-logo-white.png"/>
                                    <p>LISTEN NOW</p>
                                </a>
                            </Col>
                            <Col>
                                <a target="_blank" rel="noreferrer" href="https://music.apple.com/us/album/ride/1637972212?i=1637972213">
                                    <img alt="Apple" className="icon" src="./images/icons/apple-logo-white-cropped.png"/>
                                    <p>LISTEN NOW</p>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
          
          
        </Modal>
      </>
    );
  }