import React from "react"
import Nav from "react-bootstrap/Nav"
import Navbar  from "react-bootstrap/Navbar"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import useWindowDimensions from "../../Hooks/UseWindowDimensions"

import "./Navbar.css"


export default function NavBar(props) {
    const {width} = useWindowDimensions()
    const xs = width < 576
    const sm = width >= 576 && width <768
    const md = width >= 768 && width < 992
    // const lg = width >=992 

    const links = [
        {name: "Home", link: "/"},
        {name: "About", link: "/about"},
        {name: "Contact", link: "#contact"}
    ]
    return (
        <Navbar bsPrefix="Navbar" expand="lg" sticky="top">
            <Container style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
                <Nav bsPrefix="Nav" className="justify-content-center" activeKey={props.activeKey} style={{justifyContent:"center", display:"flex"}}>
                    <Row style={{maxWidth:"400px"}}>
                    {links.map( (link) => {
                        return (
                            <Col xs={4}>
                                <Nav.Item bsPrefix="NavItem">
                                    <Nav.Link 
                                        bsPrefix={props.activeKey === link.link?"SelectedNavLink":"NavLink"} 
                                        href={link.link}
                                        style={props.activeKey === link.link? {
                                            fontSize: xs? "18px": sm? "20px" : md? "22px": "22px"
                                        } : {
                                            fontSize: xs? "16px": sm? "18px" : md? "20px": "20px"
                                        }}
                                    >
                                        {link.name}
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                        )
                    })}
                    </Row>
                </Nav>
            </Container>
         </Navbar>
    )
}


// import React from "react"
// import Nav from "react-bootstrap/Nav"
// import Navbar  from "react-bootstrap/Navbar"
// import Col from "react-bootstrap/Col"
// import Row from "react-bootstrap/Row"
// import Container from "react-bootstrap/Container"
// import useWindowDimensions from "../../Hooks/UseWindowDimensions"

// import "./Navbar.css"


// export default function NavBar(props) {
//     const {width} = useWindowDimensions()
//     const xs = width < 576
//     const sm = width >= 576 && width <768
//     const md = width >= 768 && width < 992
//     const lg = width >=992 

//     const links = [
//         {name: "Home", link: "/"},
//         {name: "About", link: "/about"}
//     ]
//     return (
//         <Navbar bsPrefix="Navbar" expand="lg" sticky="top">
//             <Container style={{display:"flex", justifyContent:"center", flexDirection:"row"}}>
//                 <Nav bsPrefix="Nav" className="justify-content-center" activeKey={props.activeKey} style={{justifyContent:"center", display:"flex"}}>
//                     <Row style={{maxWidth:"400px"}}>
//                     {links.map( (link) => {
//                         return (
//                             <Col xs={12/links.length}>
//                                 <Nav.Item bsPrefix="NavItem">
//                                     <Nav.Link 
//                                         bsPrefix={props.activeKey === link.link?"SelectedNavLink":"NavLink"} 
//                                         href={link.link}
//                                         style={props.activeKey === link.link? {
//                                             fontSize: xs? "16px": sm? "20px" : md? "22px": "22px"
//                                         } : {
//                                             fontSize: xs? "14px": sm? "18px" : md? "20px": "20px"
//                                         }}
//                                     >
//                                         {link.name}
//                                     </Nav.Link>
//                                 </Nav.Item>
//                             </Col>
//                         )
//                     })}
//                     </Row>
//                 </Nav>
//             </Container>
//          </Navbar>
//     )
// }