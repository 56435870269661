import React from "react"

import NavBar from "../../Components/Navbar/NavBar"
import Music from "./components/Music"
import Socials from "./components/Socials"
import Ezogie from "./components/Ezogie"
import Videos from "./components/Videos"
import Footer from "../../Components/Footer/Footer"
import NewMusicModal from "../../Components/Modals/NewMusicModal"

import "./Home.css"

export default function Home() {
  return (
    <div style={{width:"100vw"}}>
      <NavBar activeKey={"/"}/>
      <NewMusicModal />
      <Ezogie />
      <Videos />
      <Socials />
      <Music />
      <Footer />
    </div>
  )
} 