// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AboutMe from './Pages/AboutMe/AboutMe';
import Home from './Pages/Home/Home';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Routes>
            <Route exact path='/about' element={< AboutMe />}></Route>
            <Route path='/' element={< Home />}></Route>
          </Routes>
      </div>


    </BrowserRouter>
  );
}

export default App;
