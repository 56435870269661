import React from "react";
import Row from "react-bootstrap/Row"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useWindowDimensions from "../../../Hooks/UseWindowDimensions"

export default function Music() {
    const {width} = useWindowDimensions()
    const xs = width < 576
    const sm = width >= 576 && width <768
    const md = width >= 768 && width < 992
    // const lg = width >=992 
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 1400 },
          items: 4,
          partialVisibilityGutter: 40 
        },
        desktop: {
          breakpoint: { max: 1400, min: 1024 },
          items: 3,
          partialVisibilityGutter: 80
        },
        tablet: {
          breakpoint: { max: 1024, min: 730 },
          items: 2,
          partialVisibilityGutter: 20
        },
        mobile: {
          breakpoint: { max: 730, min: 0 },
          items: 1,
          partialVisibilityGutter: 0
        }
    };

    return(
        <Row id="Music"
        style={{
          padding:!xs? "6rem 6rem" : "6rem 20px",
          background:"linear-gradient(to bottom right, black, #060409, #251337, black, #2e1256, #63319b, black,  #2e1256,  #251337 )", 
          height:"100vh", 
        }}>
        <h1 class="subtitle" style={{color:"white", textAlign:"left", fontSize: xs? "32px": sm? "48px" : md? "48px": "48px"}}>Music</h1>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlay={true}
                autoPlaySpeed={5000}
                // removeArrowOnDeviceType={[ "mobile"]}
            >
                
                <div>
                    <iframe title="spotify-ride" src="https://open.spotify.com/embed/track/4EdxOaDksCD16JIK9A5b6H" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
                <div>
                    <iframe title="spotify-set-me-free" src="https://open.spotify.com/embed/track/6TJ5rxWgD5UFrND5hcVAHd" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
                {/* <div>
                    <iframe title="spotify-hurt" src="https://open.spotify.com/embed/track/6zfdz213iAQCuxqSw8I07c" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div> */}
                <div>
                    <iframe title="spotify-omw-clean" src="https://open.spotify.com/embed/track/44ie96S4pOmSh0A8y1xQI5" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
                <div>
                  <iframe title="spotify-no-cash" src="https://open.spotify.com/embed/track/3LLWbm5JAuNw7ibtlA4Mwy" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
                <div>
                    <iframe title="spotify-friend-clean" src="https://open.spotify.com/embed/track/3t0SSnEcgXIL9dLWPFFsru" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
                {/* <div>
                    <iframe title="spotify-21" src="https://open.spotify.com/embed/track/4FyNLJBAPsc6UIuRSx6GoP" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
                <div>
                    <iframe title="spotify-omw-explicit" src="https://open.spotify.com/embed/track/1Br1p42lbe42HdxOziY5aD" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
                <div>
                    <iframe title="spotify-friend-explicit" src="https://open.spotify.com/embed/track/3P7FtLVO4xV95BEmxP0SKB" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div> */}
            </Carousel>
      </Row>
    )
}