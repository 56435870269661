import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import useWindowDimensions from "../../../Hooks/UseWindowDimensions"

export default function Socials() {
    const {width} = useWindowDimensions()
    const xs = width < 576
    const sm = width >= 576 && width <768
    const md = width >= 768 && width < 992
    const lg = width >=992 
    return(
      <Row id="Socials"
      style={{
        padding: !xs? "6rem 6rem" : "6rem 20px",
        background: lg? "linear-gradient(to right, #251337, #251337, transparent ), url('./images/ezogie-tree.jpg') right no-repeat": "url('./images/ezogie-tree-branches-only.jpg') #69488a",
        minHeight:"100vh",
        backgroundBlendMode: !lg && "multiply",
        backgroundSize: !lg && "cover",
      }}>
      <Col >
        <h1 class="subtitle" style={{color:"white", paddingBottom:"4rem", textAlign:"left", fontSize: xs? "32px": sm? "48px" : md? "48px": "48px"}}>Socials</h1>
      {/* </Col>
      <Col style={{justifyContent:"center", display:"flex", flexDirection:"column", border:"green"}}> */}
        <div class="vertical-center-container" >
        {/* // style={{justifyContent:"left", alignItems:"left", alignSelf:"left", display:"flex", flexDirection:"column"}}> */}
          <div class="vertical-center">
            <Row style={{justifyContent:"center", display:"flex", maxWidth:"700px"}}>
              <Col>
                <a target="_blank" rel="noreferrer"  href="https://www.instagram.com/ezogie2/">
                  <img class="social-link" src="./images/icons/ezogie-instagram.png" alt="instagram" />
                </a> 
              </Col>
              <Col>
                <a target="_blank" rel="noreferrer"  href="https://twitter.com/queenzogie2">
                  <img class="social-link" src="./images/icons/ezogie-twitter.png"alt="twitter" />
                </a> 
              </Col>
              <Col>
                <a target="_blank" rel="noreferrer"  href="https://www.tiktok.com/@ezogie">
                  <img class="social-link" src="./images/icons/ezogie-tiktok.png" alt="tiktok" />
                </a> 
              </Col>
            </Row>
            <Row style={{maxWidth: "700px"}}>
              <Col xs={4}>
                <a target="_blank" rel="noreferrer"  href="https://www.youtube.com/channel/UCp659RPtAj7vvGdV0mU0aMg">
                  <img class="social-link" src="./images/icons/ezogie-youtube.png" alt="youtube" />
                </a> 
              </Col>
              <Col xs={4}>
                <a target="_blank" rel="noreferrer"  href="https://www.facebook.com/ehizogie.igbinedion">
                  <img class="social-link" src="./images/icons/ezogie-facebook.png" alt="facebook" />
                </a> 
              </Col>
            </Row>        
          </div>
        </div>
      </Col>

    </Row>
    )
}