import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import NavBar from "../../Components/Navbar/NavBar"
import "../Home/Home.css"
import useWindowDimensions from "../../Hooks/UseWindowDimensions"
import Footer from "../../Components/Footer/Footer"


export default function AboutMe() {

  const {width} = useWindowDimensions()
  const xs = width < 576
  const sm = width >= 576 && width <768
  const md = width >= 768 && width < 992
  // const lg = width >=992 && width < 1200
  const xl =  width >=1200

  const paragraphSettings = {
    textAlign:"left", 
    fontFamily:"Marcellus", 
    color:"white",
    fontSize: xs? "12px": sm? "14px" : md? "16px": "16px"
  }
  return (
    <div>
      <NavBar activeKey={"/about"}/>
        <Row 
        style={{
          padding: !xs? "6rem 6rem" : "6rem 20px",
          background: xl? "linear-gradient(to right, #251337, #251337, transparent ), url('./images/ezogie-snow-cropped.jpg') right no-repeat": "url('./images/ezogie-snow.jpg') #69488a no-repeat",
          minHeight:"100vh",
          backgroundBlendMode:  "multiply",
          backgroundSize: "cover",
        }}>
        <Col xs={12} xl={6}>
          <h1 class="subtitle" style={{color:"white", paddingBottom:"4rem", textAlign:"left", fontSize: xs? "32px": sm? "48px" : md? "48px": "48px"}}>Bio</h1>
          <p style={paragraphSettings}>
            Ehizogie Igbinedion commonly known as Ezogie is a singer, rapper, and songwriter from Benin City, Nigeria, but grew up in Palm Desert, California. She currently resides in New York City, New York.
          </p>
          <p style={paragraphSettings}>
            At the age of eight is when she started her journey of songwriting. Writing music has always been a way for Ezogie to express her emotions, and to navigate through an environment that is constantly changing. At the age of five her life changed forever when she moved from Nigeria to the U.S.A  in the pursuit for a better life. Like most immigrants, that transition is a very difficult one for her, especially being so young and having to integrate into a completely different culture. 
          </p>
          <p style={paragraphSettings}>
            Music has always been a way for Ezogie to be vulnerable, and to finally speak up in ways she could not normally. In 2021, her debut single “No Cash” premiered, which was a song about finding love in spite of battles with insecurities, and pain from past relationships. Later that year her debut ep, “Hurt” released, which detailed the journey of finding yourself again after battles with mental health issues, and broken relationships with friends and partners. October of 2021 “Set Me Free,” released which talks about issues relating to stalking and harassment. 
          </p>
          <p style={paragraphSettings}>
            Ezogie plans to release more music in 2022, with “Misused,” set to release February 2022, along with her debut music video. She is also anticipating more single releases and a second EP to be released by the end of the year. Expect her music to grow as she does musically. 
          </p>
          <p style={paragraphSettings}>
            Ezogie hopes that her music can be an outlet to those who has ever struggled mentally, emotionally, physically. She wants her music to also make people happy, nostalgia, and get whatever is bothering someone that day off their chest. 
          </p>
        </Col>
      </Row>
      <Footer /> 
    </div>
  )
}