import React from "react"

import "./Footer.css"


export default function Footer(props) {
    return (
        <div className="footer" id="Footer">
            <h4 className="footer-text" id="contact">Contact us at ezogie925@gmail.com</h4>
        </div>
    )
}